import React from 'react';
import { Image, Row } from 'react-bootstrap';
import { Discord, Instagram, Twitter } from 'react-bootstrap-icons';
import logo from '../images/logo.png';

export default function Footer() {
  return (
    <section className='footer'>
      <div className='footer--wrapper d-flex flex-column align-items-center justify-content-end'>
        <Row className='d-flex mb-5'>
          <Image className='footer--logo' src={logo} alt='ghxst logo' />
        </Row>

        <Row className='d-flex mb-4'>
          <div className='footer--social d-flex justify-content-center'>
            <a
              href='https://twitter.com/Ghxsts'
              target='_blank'
              rel='noreferrer'
            >
              <Twitter
                className='footer--social--icons'
                color={'white'}
                size={28}
              />
            </a>
            {/* <a
              href='https://discord.com/invite/qeDhyhJKkn'
              target='_blank'
              rel='noreferrer'
            >
              <Discord
                className='footer--social--icons'
                color={'white'}
                size={28}
              />
            </a> */}
            <a
              href='https://www.instagram.com/ghxsts_official/'
              target='_blank'
              rel='noreferrer'
            >
              <Instagram
                className='footer--social--icons'
                color={'white'}
                size={28}
              />
            </a>
          </div>
        </Row>
      </div>
    </section>
  );
}
