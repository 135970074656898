import React from 'react';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

export default function SinglePieceCombinationCard({ combinationData }) {
  const { name, collectionName, imageUrl, id, slug } = combinationData;

  const splitImageUrlForSmallerImages = imageUrl.split('=');
  const newUrl = splitImageUrlForSmallerImages[0] + '=s200';

  return (
    <Link
      to={`/browse/${slug}`}
      className='browse--card--link'
      style={{ color: '#fff' }}
    >
      <Card className='combination--card'>
        <Card.Img
          className='combination--card--image'
          variant='top'
          src={newUrl}
        />
        <Card.Body className='combination--card--body d-flex justify-content-between'>
          <div>
            <p className='combination--card--combinationName'>{name}</p>
            <p className='combination--card--collectionName'>
              {collectionName}
            </p>
          </div>
          {/* <a className='align-self-center' href={`/browse/${id}`}>
            <Button size='sm' variant='primary'>
              View
            </Button>
          </a> */}
        </Card.Body>
      </Card>
    </Link>
  );
}
