import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AuthContext } from '../context/auth';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import DeleteButton from './DeleteModal';

export default function BrowseCard({
  post: { name, collectionName, imageUrl, id, slug },
}) {
  const { user } = useContext(AuthContext);

  const splitImageUrlForSmallerImages = imageUrl.split('=');
  const newUrl = splitImageUrlForSmallerImages[0] + '=s200';

  return (
    <div>
      <Link
        to={`/browse/${slug}`}
        className='browse--card--link'
        style={{ color: '#fff' }}
      >
        <Card className='browse--card'>
          <Card.Img variant='top browse--card--image' src={newUrl} />
          <Card.Body className='browse--card--body'>
            <Card.Title className='browse--card--title'>{name}</Card.Title>
            <Card.Text>{collectionName}</Card.Text>
          </Card.Body>
        </Card>
      </Link>
      {user && (
        <div className='delete-buttons-browse'>
          <Button
            className='mr-2'
            size='sm'
            variant='warning'
            href={`/edit/${slug}`}
          >
            Edit
          </Button>
          <DeleteButton postId={id} />
        </div>
      )}
    </div>
  );
}
