import React, { useContext, useState } from 'react';

import { useMutation, gql } from '@apollo/client';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useForm } from '../util/hooks';
import { AuthContext } from '../context/auth';

export default function Register(props) {
  const context = useContext(AuthContext);
  const [errors, setErrors] = useState({});

  const { onChange, onSubmit, values } = useForm(registerUser, {
    username: '',
    password: '',
    email: '',
    confirmPassword: '',
    signupKey: '',
  });

  const [addUser, { loading }] = useMutation(REGISTER_USER, {
    //destructure data from results, then destructure register from data, finally give register the alias of userData
    update(_, { data: { register: userData } }) {
      context.login(userData);
      props.history.push('/');
    },
    onError(err) {
      setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
    variables: values,
  });

  function registerUser() {
    addUser();
  }

  return (
    <Row className='bg-white mt-5 py-5 justify-content-center'>
      <Col sm={8} md={6} lg={4}>
        <h1 className='text-center'>Register</h1>
        <Form onSubmit={onSubmit}>
          <Form.Group>
            <Form.Label className={errors.username && 'text-danger'}>
              {errors.username ?? 'Username'}
            </Form.Label>
            <Form.Control
              name='username'
              type='text'
              value={values.username}
              className={errors.username && 'is-invalid'}
              onChange={onChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className={errors.email && 'text-danger'}>
              {errors.email ?? 'Email'}
            </Form.Label>
            <Form.Control
              name='email'
              type='text'
              value={values.email}
              className={errors.email && 'is-invalid'}
              onChange={onChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className={errors.password && 'text-danger'}>
              {errors.password ?? 'Password'}
            </Form.Label>
            <Form.Control
              name='password'
              type='password'
              value={values.password}
              className={errors.password && 'is-invalid'}
              onChange={onChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className={errors.confirmPassword && 'text-danger'}>
              {errors.confirmPassword ?? 'Confirm Password'}
            </Form.Label>
            <Form.Control
              name='confirmPassword'
              type='confirmPassword'
              value={values.confirmPassword}
              className={errors.confirmPassword && 'is-invalid'}
              onChange={onChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className={errors.signupKey && 'text-danger'}>
              {errors.signupKey ?? 'Signup Key'}
            </Form.Label>
            <Form.Control
              name='signupKey'
              type='signupKey'
              value={values.signupKey}
              className={errors.signupKey && 'is-invalid'}
              onChange={onChange}
            />
          </Form.Group>
          <div className='text-center'>
            <Button variant='success' type='submit' disabled={loading}>
              {loading ? 'loading..' : 'Login'}
            </Button>
            <br />
            <small>
              Already have an account? <Link to='/login'>Login</Link>
            </small>
          </div>
        </Form>
        {Object.keys(errors).length > 0 && (
          <div className='mt-5'>
            <ul className='list'>
              {Object.values(errors).map((value) => (
                <li style={{ color: 'red' }} key={value}>
                  {value}
                </li>
              ))}
            </ul>
          </div>
        )}
      </Col>
    </Row>
  );
}

const REGISTER_USER = gql`
  mutation register(
    $username: String!
    $email: String!
    $password: String!
    $confirmPassword: String!
    $signupKey: String!
  ) {
    register(
      registerInput: {
        username: $username
        email: $email
        password: $password
        confirmPassword: $confirmPassword
        signupKey: $signupKey
      }
    ) {
      id
      email
      username
      createdAt
      token
    }
  }
`;
