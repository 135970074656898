import React, { useContext } from 'react';

import { FilterSearchContext } from '../context/FilterSearch';

import Button from 'react-bootstrap/Button';
import { XCircleFill } from 'react-bootstrap-icons';

export default function SelectedTraits() {
  const { selected, handleClick } = useContext(FilterSearchContext);
  const {
    collectionName,
    eyes,
    xEyes,
    hairStyle,
    customTraits,
    body,
    heart,
    blood,
    personality,
    power,
    companion,
    sign,
    soul,
  } = selected;

  const changeCamelToString = (camelCasedWord) => {
    return (
      camelCasedWord
        .replace(/([A-Z])/g, ' $1')
        // uppercase the first character
        .replace(/^./, function (str) {
          return str.toUpperCase();
        })
    );
  };

  return (
    <div>
      {collectionName.map((trait) => (
        <Button
          className='selected-button-button'
          variant='secondary'
          onClick={() => handleClick(trait, 'collectionName')}
          style={{ position: 'relative' }}
          size='sm'
          key={`Collection: ${trait}`}
        >
          Collection: {trait}
          <XCircleFill className='selected-button-x' size={18} color='white' />
        </Button>
      ))}

      {body.map((trait) => (
        <Button
          className='selected-button-button'
          variant='secondary'
          onClick={() => handleClick(trait, 'body')}
          style={{ position: 'relative' }}
          size='sm'
          key={`Body: ${trait}`}
        >
          Body: {changeCamelToString(trait)}
          <XCircleFill className='selected-button-x' size={18} color='white' />
        </Button>
      ))}

      {hairStyle.map((trait) => (
        <Button
          className='selected-button-button'
          variant='secondary'
          onClick={() => handleClick(trait, 'hairStyle')}
          style={{ position: 'relative' }}
          size='sm'
          key={`hairStyle: ${trait}`}
        >
          Hair Style: {changeCamelToString(trait)}
          <XCircleFill className='selected-button-x' size={18} color='white' />
        </Button>
      ))}

      {eyes.map((trait) => (
        <Button
          className='selected-button-button'
          variant='secondary'
          onClick={() => handleClick(trait, 'eyes')}
          style={{ position: 'relative' }}
          size='sm'
          key={`eyes: ${trait}`}
        >
          Eyes: {changeCamelToString(trait)}
          <XCircleFill className='selected-button-x' size={18} color='white' />
        </Button>
      ))}

      {xEyes.map((trait) => (
        <Button
          className='selected-button-button'
          variant='secondary'
          onClick={() => handleClick(trait, 'xEyes')}
          style={{ position: 'relative' }}
          size='sm'
          key={`Collection: ${trait}`}
        >
          X Eyes: {trait}
          <XCircleFill className='selected-button-x' size={18} color='white' />
        </Button>
      ))}
      {heart.map((trait) => (
        <Button
          className='selected-button-button'
          variant='secondary'
          onClick={() => handleClick(trait, 'heart')}
          style={{ position: 'relative' }}
          size='sm'
          key={`Heart: ${trait}`}
        >
          Heart: {trait}
          <XCircleFill className='selected-button-x' size={18} color='white' />
        </Button>
      ))}
      {blood.map((trait) => (
        <Button
          className='selected-button-button'
          variant='secondary'
          onClick={() => handleClick(trait, 'blood')}
          style={{ position: 'relative' }}
          size='sm'
          key={`Blood: ${trait}`}
        >
          Blood: {trait}
          <XCircleFill className='selected-button-x' size={18} color='white' />
        </Button>
      ))}
      {personality.map((trait) => (
        <Button
          className='selected-button-button'
          variant='secondary'
          onClick={() => handleClick(trait, 'personality')}
          style={{ position: 'relative' }}
          size='sm'
          key={`Personality: ${trait}`}
        >
          Personality: {trait}
          <XCircleFill className='selected-button-x' size={18} color='white' />
        </Button>
      ))}
      {power.map((trait) => (
        <Button
          className='selected-button-button'
          variant='secondary'
          onClick={() => handleClick(trait, 'power')}
          style={{ position: 'relative' }}
          size='sm'
          key={`Power: ${trait}`}
        >
          Power: {trait}
          <XCircleFill className='selected-button-x' size={18} color='white' />
        </Button>
      ))}
      {companion.map((trait) => (
        <Button
          className='selected-button-button'
          variant='secondary'
          onClick={() => handleClick(trait, 'companion')}
          style={{ position: 'relative' }}
          size='sm'
          key={`Companion: ${trait}`}
        >
          Companion: {trait}
          <XCircleFill className='selected-button-x' size={18} color='white' />
        </Button>
      ))}

      {customTraits.map((trait) => (
        <Button
          className='selected-button-button'
          variant='secondary'
          onClick={() => handleClick(trait, 'customTraits')}
          style={{ position: 'relative' }}
          size='sm'
          key={`Custom Trait: ${trait}`}
        >
          Rarity: {changeCamelToString(trait)}
          <XCircleFill className='selected-button-x' size={18} color='white' />
        </Button>
      ))}
      {sign.map((trait) => (
        <Button
          className='selected-button-button'
          variant='secondary'
          onClick={() => handleClick(trait, 'sign')}
          style={{ position: 'relative' }}
          size='sm'
          key={`Sign: ${trait}`}
        >
          Sign: {changeCamelToString(trait)}
          <XCircleFill className='selected-button-x' size={18} color='white' />
        </Button>
      ))}
      {soul.map((trait) => (
        <Button
          className='selected-button-button'
          variant='secondary'
          onClick={() => handleClick(trait, 'soul')}
          style={{ position: 'relative' }}
          size='sm'
          key={`Custom Trait: ${trait}`}
        >
          Soul: {changeCamelToString(trait)}
          <XCircleFill className='selected-button-x' size={18} color='white' />
        </Button>
      ))}
    </div>
  );
}
