import React, { useContext } from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FilterBar from './FilterBar';

import { FilterSearchContext } from '../context/FilterSearch';

export default function FilterModal({ modalOpen, handleClose }) {
  const { selected, setSelected, initialState } =
    useContext(FilterSearchContext);

  const clearAll = () => {
    setSelected(initialState);
  };
  return (
    <Modal show={modalOpen} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Filter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FilterBar></FilterBar>
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-between'>
        <div className='clear-all-button align-self-center' onClick={clearAll}>
          Clear all
        </div>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
