import React, { useContext, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Row, Col, Form, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { AuthContext } from '../context/auth';
import { useForm } from '../util/hooks.js';

function Login(props) {
  const context = useContext(AuthContext);
  const [errors, setErrors] = useState({});

  const { onChange, onSubmit, values } = useForm(loginUserCallback, {
    username: '',
    password: '',
  });

  const [loginUser, { loading }] = useMutation(LOGIN_USER, {
    update(_, { data: { login: userData } }) {
      context.login(userData);
      props.history.push('/');
    },
    onError(err) {
      setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
    variables: values,
  });

  function loginUserCallback() {
    loginUser();
  }

  return (
    <Container style={{ backgroundColor: '#2f2f2f', marginTop: '8rem' }}>
      <Row className='py-5 justify-content-center'>
        <Col sm={8} md={6} lg={4}>
          <h1 className='text-center'>Login</h1>
          <Form onSubmit={onSubmit}>
            <Form.Group>
              <Form.Label className={errors.username && 'text-danger'}>
                {errors.username ?? 'Username'}
              </Form.Label>
              <Form.Control
                name='username'
                type='text'
                value={values.username}
                className={errors.username && 'is-invalid'}
                onChange={onChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className={errors.password && 'text-danger'}>
                {errors.password ?? 'Password'}
              </Form.Label>
              <Form.Control
                name='password'
                type='password'
                value={values.password}
                className={errors.password && 'is-invalid'}
                onChange={onChange}
              />
            </Form.Group>
            <div className='text-center'>
              <Button variant='success' type='submit' disabled={loading}>
                {loading ? 'loading..' : 'Login'}
              </Button>
              <br />
              {/* <small>
                Don't have an account? <Link to='/register'>Register</Link>
              </small> */}
            </div>
          </Form>
          {Object.keys(errors).length > 0 && (
            <div className='mt-5'>
              <ul className='list'>
                {Object.values(errors).map((value) => (
                  <li style={{ color: 'red' }} key={value}>
                    {value}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}

const LOGIN_USER = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      id
      email
      username
      token
    }
  }
`;

export default Login;
