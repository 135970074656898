import React, { useContext, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';

import { AuthContext } from '../context/auth';
import { FETCH_POST_QUERY } from '../util/graphql';

import DeleteButton from '../components/DeleteModal';

import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { ChevronRight, ArrowLeft } from 'react-bootstrap-icons';
import SinglePieceTraitCalculations from '../util/SinglePieceTraitCalculations';
import SinglePieceCombinationCard from '../components/SinglePieceCombinationCard';

export default function SinglePiece(props) {
  let postMarkup;
  const slug = props.match.params.slug;
  const { user } = useContext(AuthContext);

  const insertAt = (str, sub, pos) =>
    `${str.slice(0, pos)}${sub}${str.slice(pos)}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    loading,
    error,
    data: { getPost } = {},
  } = useQuery(FETCH_POST_QUERY, {
    variables: {
      slug,
    },
  });

  // formattedDate = insertAt(formattedDate, '/', 7);

  const goBack = () => {
    props.history.goBack();
  };

  if (loading) {
    postMarkup = (
      <Container
        style={{ minHeight: '45vh', marginTop: '10rem' }}
        className='d-flex justify-content-center align-items-center'
      >
        <div className='lds-dual-ring-singlepiece'></div>
      </Container>
    );
  } else if (error) {
    postMarkup = (
      <Container style={{ minHeight: '45vh', marginTop: '10rem' }}>
        <Col className='d-flex justify-content-center'>
          <Row className>
            <p className='text-center' style={{ fontSize: '2rem' }}>
              This page does not exist or something went wrong.
            </p>
          </Row>
        </Col>
      </Container>
    );
  } else {
    const {
      name,
      collectionName,
      imageUrl,
      combinations,
      piecesUsed,
      openseaUrl,
      dateReleased,
      id,
    } = getPost;

    const splitImageUrlForSmallerImages = imageUrl.split('=');
    const newUrl = splitImageUrlForSmallerImages[0] + '=s1000';

    postMarkup = (
      <>
        <Container>
          <div onClick={goBack} className='singlepiece--backLink'>
            <ArrowLeft className='singlepiece--arrow' /> Previous Page
          </div>
        </Container>

        <Container className='singlepiece--container p-4 p-md-5'>
          {user && (
            <div className='delete-buttons'>
              <Button
                className='mr-2'
                size='sm'
                variant='warning'
                href={`/edit/${slug}`}
              >
                Edit
              </Button>
              <DeleteButton postId={id} />
            </div>
          )}
          <Row className='d-flex flex-column mb-2 text-center'>
            <p className='singlepiece--collectionName'>{collectionName}</p>
            <h1 className='singlepiece--heading'>{name}</h1>
          </Row>
          <Row className='justify-content-center singlepiece--dateReleased'>
            <span>Date Released:&nbsp;</span>
            {/* {dateReleased !== null
              ? insertAt(insertAt(getPost.dateReleased, '/', 4), '/', 7)
              : 'not added yet'} */}
            {insertAt(insertAt(dateReleased, '/', 2), '/', 5)}
          </Row>
          <Row className='justify-content-center'>
            <a href={openseaUrl} target='_blank'>
              <Button variant='danger'>
                View on OpenSea
                <ChevronRight className='singlepiece--arrow' />
              </Button>
            </a>
          </Row>

          <Row className='d-flex justify-content-center'>
            <Image className='singlepiece--image' src={newUrl}></Image>
          </Row>
          <Row>
            <h5 className='trait--headings'>Traits:</h5>
          </Row>
          <Row className='trait--row'>
            <SinglePieceTraitCalculations getPost={getPost} />
          </Row>
          <Row>
            <h5 className='trait--headings'>Pieces Used:</h5>
          </Row>
          <Row>
            {piecesUsed.length > 0 ? (
              piecesUsed.map((pieceUsed) => (
                <Col xs={6} md={4} xl={3} key={pieceUsed.id}>
                  <SinglePieceCombinationCard combinationData={pieceUsed} />
                </Col>
              ))
            ) : (
              <Col>
                <Row className='virgin-text-container'>
                  <div className='virgin-text'>none</div>
                </Row>
              </Col>
            )}
          </Row>
          <Row>
            <h5 className='trait--headings'>Combinations Made:</h5>
          </Row>
          <Row>
            {combinations.length > 0 ? (
              combinations.map((combination) => (
                <Col xs={6} md={4} xl={3} key={combination.id}>
                  <SinglePieceCombinationCard combinationData={combination} />
                </Col>
              ))
            ) : (
              <Col>
                <Row className='virgin-text-container'>
                  <div className='virgin-text'>none</div>
                </Row>
              </Col>
            )}
          </Row>
        </Container>
      </>
    );
  }
  return postMarkup;
}

export const FETCH_POSTS_QUERY = gql`
  {
    getPosts {
      id
    }
  }
`;
