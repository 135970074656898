import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function DeleteButton({ postId }) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteModalClose = () => setShowDeleteModal(false);
  const handleDeleteModalShow = () => setShowDeleteModal(true);

  const [deletePost] = useMutation(DELETE_POST_MUTATION, {
    update() {
      setShowDeleteModal(false);
    },
    variables: {
      postId,
    },
  });

  return (
    <>
      <Modal show={showDeleteModal} onHide={handleDeleteModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this piece? Action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleDeleteModalClose}>
            Cancel
          </Button>
          <Button variant='danger' onClick={deletePost}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Button size='sm' onClick={handleDeleteModalShow} variant='danger'>
        Delete
      </Button>
    </>
  );
}

const DELETE_POST_MUTATION = gql`
  mutation deletePost($postId: ID!) {
    deletePost(postId: $postId)
  }
`;
