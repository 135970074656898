import React, { useState, useContext } from 'react';
import { useQuery } from '@apollo/client';

import { Plus, Dash } from 'react-bootstrap-icons';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { Form } from 'react-bootstrap';

import { FETCH_ALLTRAITS_QUERY } from '../util/graphql';
import { FilterSearchContext } from '../context/FilterSearch';
import { data as collectionData } from '../util/facetedsearchdata';
import { FilterBarSkeleton } from '../util/FilterBarSkeleton';

const getKeys = (_object) => {
  return Object.keys(_object);
};

const changeCamelToString = (camelCasedWord) => {
  return (
    camelCasedWord
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
};

export default function FilterBar() {
  const { loading, error, data } = useQuery(FETCH_ALLTRAITS_QUERY, {
    variables: { collectionName: 'All' },
  });

  const [opened, setOpened] = useState(['0']);
  const { handleClick, selected } = useContext(FilterSearchContext);

  const accordion = (event) => {
    let newOpenedList = opened;
    let name = event.target.getAttribute('name');
    if (opened.includes(name)) {
      newOpenedList = opened.filter((openedItem) => openedItem !== name);
      setOpened([...newOpenedList]);
    } else {
      setOpened([...opened, name]);
    }
  };

  let markup;
  if (loading) {
    markup = <FilterBarSkeleton />;
  } else {
    const traitData = JSON.parse(data.getTraitData.stringifiedData);

    const eyesFilterTraits = getKeys(traitData.eyes).sort();
    const xEyesFilterTraits = getKeys(traitData.xEyes).sort();
    const bodyFilterTraits = getKeys(traitData.body).sort();
    const hairStyleFilterTraits = getKeys(traitData.hairStyle).sort();
    const customTraitsFilterTraits = getKeys(traitData.customTraits).sort();
    const powerFilterTraits = getKeys(traitData.power).sort();
    const personalityFilterTraits = getKeys(traitData.personality).sort();
    const bloodFilterTraits = getKeys(traitData.blood).sort();
    const heartFilterTraits = getKeys(traitData.heart).sort();
    const companionFilterTraits = getKeys(traitData.companion).sort();
    const soulFilterTraits = getKeys(traitData.soul).sort();
    const signFilterTraits = getKeys(traitData.sign).sort();

    markup = (
      <>
        <Accordion className='filter-accordion' defaultActiveKey='0'>
          <Card className='filter-accordion-card'>
            <Accordion.Toggle
              onClick={accordion}
              name='0'
              as={Card.Header}
              eventKey='0'
            >
              Collection
              {opened.includes('0') ? (
                <Dash size={23} className='float-right caret' name='0' />
              ) : (
                <Plus size={23} className='float-right caret' name='0' />
              )}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='0'>
              <Card.Body className='filter-accordion-text'>
                <Form>
                  {collectionData.collectionName
                    .filter((trait) => trait !== 'Null')
                    .map((type) => (
                      <div key={`collection-${type}`} className='my-2 d-flex'>
                        <Form.Check
                          onChange={() => handleClick(type, 'collectionName')}
                          type='checkbox'
                          name={type}
                          checked={selected.collectionName.includes(type)}
                        />
                        <Form.Check.Label
                          className='filter-bar-label'
                          onClick={() => handleClick(type, 'collectionName')}
                        >
                          {type}
                        </Form.Check.Label>
                      </div>
                    ))}
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion name='11' onClick={accordion}>
          <Card className='filter-accordion-card'>
            <Accordion.Toggle
              onClick={accordion}
              name='11'
              as={Card.Header}
              eventKey='11'
            >
              Body
              {opened.includes('11') ? (
                <Dash size={23} className='float-right caret' name='11' />
              ) : (
                <Plus size={23} className='float-right caret' name='11' />
              )}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='11'>
              <Card.Body className='filter-accordion-text'>
                <Form>
                  {bodyFilterTraits
                    //remove null from list
                    .filter((trait) => trait !== 'Null')
                    .map((type) => (
                      <div key={`body-${type}`} className='my-2 d-flex'>
                        <Form.Check
                          onChange={() => handleClick(type, 'body')}
                          type='checkbox'
                          name={type}
                          checked={selected.body.includes(type)}
                        />
                        <Form.Check.Label
                          className='filter-bar-label'
                          onClick={() => handleClick(type, 'body')}
                        >
                          {type}
                        </Form.Check.Label>
                      </div>
                    ))}
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion name='4' onClick={accordion}>
          <Card className='filter-accordion-card'>
            <Accordion.Toggle
              onClick={accordion}
              name='4'
              as={Card.Header}
              eventKey='4'
            >
              Hair
              {opened.includes('4') ? (
                <Dash size={23} className='float-right caret' name='4' />
              ) : (
                <Plus size={23} className='float-right caret' name='4' />
              )}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='4'>
              <Card.Body className='filter-accordion-text'>
                <Form>
                  {hairStyleFilterTraits
                    //remove null from list
                    .filter((trait) => trait !== 'Null')
                    .map((type) => (
                      <div key={`hair-style-${type}`} className='my-2 d-flex'>
                        <Form.Check
                          onChange={() => handleClick(type, 'hairStyle')}
                          type='checkbox'
                          name={type}
                          checked={selected.hairStyle.includes(type)}
                        />
                        <Form.Check.Label
                          className='filter-bar-label'
                          onClick={() => handleClick(type, 'hairStyle')}
                        >
                          {type}
                        </Form.Check.Label>
                      </div>
                    ))}
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <Accordion name='1' onClick={accordion}>
          <Card className='filter-accordion-card'>
            <Accordion.Toggle
              onClick={accordion}
              name='1'
              as={Card.Header}
              eventKey='1'
            >
              Eyes
              {opened.includes('1') ? (
                <Dash size={23} className='float-right caret' name='1' />
              ) : (
                <Plus size={23} className='float-right caret' name='1' />
              )}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='1'>
              <Card.Body className='filter-accordion-text'>
                <Form>
                  {eyesFilterTraits
                    //remove null from list
                    .filter((trait) => trait !== 'Null')
                    .map((type) => (
                      <div key={`eyes-${type}`} className='my-2 d-flex'>
                        <Form.Check
                          onChange={() => handleClick(type, 'eyes')}
                          type='checkbox'
                          name={type}
                          checked={selected.eyes.includes(type)}
                        />
                        <Form.Check.Label
                          className='filter-bar-label'
                          onClick={() => handleClick(type, 'eyes')}
                        >
                          {changeCamelToString(type)}
                        </Form.Check.Label>
                      </div>
                    ))}
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion name='2' onClick={accordion}>
          <Card className='filter-accordion-card'>
            <Accordion.Toggle
              onClick={accordion}
              name='2'
              as={Card.Header}
              eventKey='2'
            >
              X Eyes
              {opened.includes('2') ? (
                <Dash size={23} className='float-right caret' name='2' />
              ) : (
                <Plus size={23} className='float-right caret' name='2' />
              )}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='2'>
              <Card.Body className='filter-accordion-text'>
                <Form>
                  {xEyesFilterTraits
                    //remove null from list
                    .filter((trait) => trait !== 'Null')
                    .map((type) => (
                      <div key={`xEyes-${type}`} className='my-2 d-flex'>
                        <Form.Check
                          onChange={() => handleClick(type, 'xEyes')}
                          type='checkbox'
                          name={type}
                          checked={selected.xEyes.includes(type)}
                        />
                        <Form.Check.Label
                          className='filter-bar-label'
                          onClick={() => handleClick(type, 'xEyes')}
                        >
                          {type}
                        </Form.Check.Label>
                      </div>
                    ))}
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <Accordion name='15' onClick={accordion}>
          <Card className='filter-accordion-card'>
            <Accordion.Toggle
              onClick={accordion}
              name='15'
              as={Card.Header}
              eventKey='15'
            >
              Heart
              {opened.includes('15') ? (
                <Dash size={23} className='float-right caret' name='15' />
              ) : (
                <Plus size={23} className='float-right caret' name='15' />
              )}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='15'>
              <Card.Body className='filter-accordion-text'>
                <Form>
                  {heartFilterTraits
                    //remove null from list
                    .filter((trait) => trait !== 'Null')
                    .map((type) => (
                      <div key={`heart-${type}`} className='my-2 d-flex'>
                        <Form.Check
                          onChange={() => handleClick(type, 'heart')}
                          type='checkbox'
                          name={type}
                          checked={selected.heart.includes(type)}
                        />
                        <Form.Check.Label
                          className='filter-bar-label'
                          onClick={() => handleClick(type, 'heart')}
                        >
                          {type}
                        </Form.Check.Label>
                      </div>
                    ))}
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <Accordion name='16' onClick={accordion}>
          <Card className='filter-accordion-card'>
            <Accordion.Toggle
              onClick={accordion}
              name='16'
              as={Card.Header}
              eventKey='16'
            >
              Blood
              {opened.includes('16') ? (
                <Dash size={23} className='float-right caret' name='16' />
              ) : (
                <Plus size={23} className='float-right caret' name='16' />
              )}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='16'>
              <Card.Body className='filter-accordion-text'>
                <Form>
                  {bloodFilterTraits
                    //remove null from list
                    .filter((trait) => trait !== 'Null')
                    .map((type) => (
                      <div key={`blood-${type}`} className='my-2 d-flex'>
                        <Form.Check
                          onChange={() => handleClick(type, 'blood')}
                          type='checkbox'
                          name={type}
                          checked={selected.blood.includes(type)}
                        />
                        <Form.Check.Label
                          className='filter-bar-label'
                          onClick={() => handleClick(type, 'blood')}
                        >
                          {type}
                        </Form.Check.Label>
                      </div>
                    ))}
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <Accordion name='14' onClick={accordion}>
          <Card className='filter-accordion-card'>
            <Accordion.Toggle
              onClick={accordion}
              name='14'
              as={Card.Header}
              eventKey='14'
            >
              Personality
              {opened.includes('14') ? (
                <Dash size={23} className='float-right caret' name='14' />
              ) : (
                <Plus size={23} className='float-right caret' name='14' />
              )}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='14'>
              <Card.Body className='filter-accordion-text'>
                <Form>
                  {personalityFilterTraits
                    //remove null from list
                    .filter((trait) => trait !== 'Null')
                    .map((type) => (
                      <div key={`personality-${type}`} className='my-2 d-flex'>
                        <Form.Check
                          onChange={() => handleClick(type, 'personality')}
                          type='checkbox'
                          name={type}
                          checked={selected.personality.includes(type)}
                        />
                        <Form.Check.Label
                          className='filter-bar-label'
                          onClick={() => handleClick(type, 'personality')}
                        >
                          {type}
                        </Form.Check.Label>
                      </div>
                    ))}
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <Accordion name='13' onClick={accordion}>
          <Card className='filter-accordion-card'>
            <Accordion.Toggle
              onClick={accordion}
              name='13'
              as={Card.Header}
              eventKey='13'
            >
              Power
              {opened.includes('13') ? (
                <Dash size={23} className='float-right caret' name='13' />
              ) : (
                <Plus size={23} className='float-right caret' name='13' />
              )}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='13'>
              <Card.Body className='filter-accordion-text'>
                <Form>
                  {powerFilterTraits
                    //remove null from list
                    .filter((trait) => trait !== 'Null')
                    .map((type) => (
                      <div key={`power-${type}`} className='my-2 d-flex'>
                        <Form.Check
                          onChange={() => handleClick(type, 'power')}
                          type='checkbox'
                          name={type}
                          checked={selected.power.includes(type)}
                        />
                        <Form.Check.Label
                          className='filter-bar-label'
                          onClick={() => handleClick(type, 'power')}
                        >
                          {type}
                        </Form.Check.Label>
                      </div>
                    ))}
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <Accordion name='17' onClick={accordion}>
          <Card className='filter-accordion-card'>
            <Accordion.Toggle
              onClick={accordion}
              name='17'
              as={Card.Header}
              eventKey='17'
            >
              Companion
              {opened.includes('17') ? (
                <Dash size={23} className='float-right caret' name='17' />
              ) : (
                <Plus size={23} className='float-right caret' name='17' />
              )}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='17'>
              <Card.Body className='filter-accordion-text'>
                <Form>
                  {companionFilterTraits //remove null from list
                    .filter((trait) => trait !== 'Null')
                    .map((type) => (
                      <div key={`companion-${type}`} className='my-2 d-flex'>
                        <Form.Check
                          onChange={() => handleClick(type, 'companion')}
                          type='checkbox'
                          name={type}
                          checked={selected.companion.includes(type)}
                        />
                        <Form.Check.Label
                          className='filter-bar-label'
                          onClick={() => handleClick(type, 'companion')}
                        >
                          {type}
                        </Form.Check.Label>
                      </div>
                    ))}
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion name='12' onClick={accordion}>
          <Card className='filter-accordion-card'>
            <Accordion.Toggle
              onClick={accordion}
              name='12'
              as={Card.Header}
              eventKey='12'
            >
              Rarity
              {opened.includes('12') ? (
                <Dash size={23} className='float-right caret' name='12' />
              ) : (
                <Plus size={23} className='float-right caret' name='12' />
              )}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='12'>
              <Card.Body className='filter-accordion-text'>
                <Form>
                  {customTraitsFilterTraits //remove null from list
                    .filter((trait) => trait !== 'Null')
                    .map((type) => (
                      <div key={`customTraits-${type}`} className='my-2 d-flex'>
                        <Form.Check
                          onChange={() => handleClick(type, 'customTraits')}
                          type='checkbox'
                          name={type}
                          checked={selected.customTraits.includes(type)}
                        />
                        <Form.Check.Label
                          className='filter-bar-label'
                          onClick={() => handleClick(type, 'customTraits')}
                        >
                          {type}
                        </Form.Check.Label>
                      </div>
                    ))}
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion name='soul' onClick={accordion}>
          <Card className='filter-accordion-card'>
            <Accordion.Toggle
              onClick={accordion}
              name='soul'
              as={Card.Header}
              eventKey='soul'
            >
              Soul
              {opened.includes('soul') ? (
                <Dash size={23} className='float-right caret' name='soul' />
              ) : (
                <Plus size={23} className='float-right caret' name='soul' />
              )}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='soul'>
              <Card.Body className='filter-accordion-text'>
                <Form>
                  {soulFilterTraits //remove null from list
                    .filter((trait) => trait !== 'Null')
                    .map((type) => (
                      <div key={`soul-${type}`} className='my-2 d-flex'>
                        <Form.Check
                          onChange={() => handleClick(type, 'soul')}
                          type='checkbox'
                          name={type}
                          checked={selected.soul.includes(type)}
                        />
                        <Form.Check.Label
                          className='filter-bar-label'
                          onClick={() => handleClick(type, 'soul')}
                        >
                          {type}
                        </Form.Check.Label>
                      </div>
                    ))}
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion name='18' onClick={accordion}>
          <Card className='filter-accordion-card'>
            <Accordion.Toggle
              onClick={accordion}
              name='18'
              as={Card.Header}
              eventKey='18'
            >
              Sign
              {opened.includes('18') ? (
                <Dash size={23} className='float-right caret' name='18' />
              ) : (
                <Plus size={23} className='float-right caret' name='18' />
              )}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='18'>
              <Card.Body className='filter-accordion-text'>
                <Form>
                  {signFilterTraits //remove null from list
                    .filter((trait) => trait !== 'Null')
                    .map((type) => (
                      <div key={`sign-${type}`} className='my-2 d-flex'>
                        <Form.Check
                          onChange={() => handleClick(type, 'sign')}
                          type='checkbox'
                          name={type}
                          checked={selected.sign.includes(type)}
                        />
                        <Form.Check.Label
                          className='filter-bar-label'
                          onClick={() => handleClick(type, 'sign')}
                        >
                          {type}
                        </Form.Check.Label>
                      </div>
                    ))}
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </>
    );
  }

  return <div className='filter-bar'>{markup}</div>;
}
