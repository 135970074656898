import { gql } from '@apollo/client';

export const FETCH_POSTS_QUERY = gql`
  {
    getPosts {
      id
      name
      collectionName
      imageUrl
      slug
    }
  }
`;

export const SEARCH_POSTS_QUERY = gql`
  query searchPosts(
    $collectionName: [String]
    $eyes: [String]
    $xEyes: [String]
    $hairStyle: [String]
    $body: [String]
    $customTraits: [String]
    $power: [String]
    $personality: [String]
    $heart: [String]
    $blood: [String]
    $companion: [String]
    $sign: [String]
    $soul: [String]
    $offset: Int
  ) {
    searchPosts(
      filterSearchInput: {
        collectionName: $collectionName
        eyes: $eyes
        xEyes: $xEyes
        hairStyle: $hairStyle
        body: $body
        customTraits: $customTraits
        power: $power
        personality: $personality
        heart: $heart
        soul: $soul
        sign: $sign
        blood: $blood
        companion: $companion
      }
      offset: $offset
    ) {
      docs {
        imageUrl
        name
        collectionName
        id
        slug
      }
      totalDocs
      totalPages
      page
      hasNextPage
    }
  }
`;

export const FETCH_ALLTRAITS_QUERY = gql`
  query ($collectionName: String!) {
    getTraitData(collectionName: $collectionName) {
      totalCount
      stringifiedData
    }
  }
`;

export const CREATE_POST_MUTATION = gql`
  mutation createPost(
    $name: String!
    $collectionName: String!
    $imageUrl: String!
    $openseaUrl: String!
    $dateReleased: String!
    $eyes: String!
    $xEyes: String!
    $hairStyle: String!
    $body: String!
    $power: String!
    $personality: String!
    $heart: String!
    $blood: String!
    $companion: String!
    $customTraits: [CustomTraitInput]
    $combinations: String
    $sign: String
    $soul: String
    $piecesUsed: String
  ) {
    createPost(
      createPostInput: {
        name: $name
        collectionName: $collectionName
        imageUrl: $imageUrl
        openseaUrl: $openseaUrl
        dateReleased: $dateReleased
        eyes: $eyes
        xEyes: $xEyes
        hairStyle: $hairStyle
        body: $body
        power: $power
        personality: $personality
        heart: $heart
        blood: $blood
        companion: $companion
        sign: $sign
        soul: $soul
        customTraits: $customTraits
        combinations: $combinations
        piecesUsed: $piecesUsed
      }
    ) {
      id
      slug
    }
  }
`;

export const EDIT_POST_MUTATION = gql`
  mutation editPost(
    $postId: ID
    $name: String!
    $collectionName: String!
    $imageUrl: String!
    $openseaUrl: String!
    $dateReleased: String!
    $eyes: String!
    $xEyes: String!
    $hairStyle: String!
    $body: String!
    $power: String!
    $personality: String!
    $heart: String!
    $blood: String!
    $companion: String!
    $soul: String
    $sign: String
    $customTraits: [CustomTraitInput]
    $combinations: String
    $piecesUsed: String
  ) {
    editPost(
      createPostInput: {
        postId: $postId
        name: $name
        collectionName: $collectionName
        imageUrl: $imageUrl
        openseaUrl: $openseaUrl
        dateReleased: $dateReleased
        eyes: $eyes
        xEyes: $xEyes
        hairStyle: $hairStyle
        body: $body
        power: $power
        personality: $personality
        heart: $heart
        blood: $blood
        sign: $sign
        soul: $soul
        companion: $companion
        customTraits: $customTraits
        combinations: $combinations
        piecesUsed: $piecesUsed
      }
    ) {
      id
      slug
    }
  }
`;

export const FETCH_POST_QUERY = gql`
  query ($slug: String!) {
    getPost(slug: $slug) {
      id
      name
      collectionName
      imageUrl
      openseaUrl
      dateReleased
      eyes
      xEyes
      body
      hairStyle
      power
      personality
      blood
      heart
      sign
      soul
      companion
      piecesUsed {
        id
        imageUrl
        name
        collectionName
        slug
      }
      combinations {
        id
        imageUrl
        name
        collectionName
        slug
      }
      customTraits {
        trait
      }
    }
  }
`;
