import React from 'react';

import Col from 'react-bootstrap/Col';

const changeCamelToString = (camelCasedWord) => {
  return (
    camelCasedWord
      .replace(/([A-Z])/g, ' $1')
      .replace(/(-)\s/g, '-')
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
};

export default function SinglePieceTraitSquare({
  collectionName,
  category,
  trait,
  totalCount,
  collectionCount,
  collectionData,
  allData,
}) {
  const calculateCollectionPercentage = (_category, _traitOfPiece) => {
    let percentage = (
      (collectionData[_category][_traitOfPiece] / collectionCount) *
      100
    ).toFixed(2);

    return percentage;
  };

  const calculateAllPercentage = (_category, _traitOfPiece) => {
    let percentage = (
      (allData[_category][_traitOfPiece] / totalCount) *
      100
    ).toFixed(2);
    return percentage;
  };

  let traitSquare;

  if (trait.indexOf(',') > -1) {
    const splitTraitsList = trait.split(',');
    const cleanedSplitTraitsList = splitTraitsList.map((trait) => trait.trim());

    traitSquare = cleanedSplitTraitsList.map((trait) => (
      <Col xs={6} md={4} lg={3}>
        <div className='trait--container d-flex flex-column justify-content-center'>
          <div className='trait--type'>{changeCamelToString(category)}</div>
          <div className='trait--details'>{changeCamelToString(trait)}</div>
          <div className='trait--stats'>
            All: {calculateAllPercentage(category, trait)}%
          </div>
          <div className='trait--stats'>
            {collectionName}: {calculateCollectionPercentage(category, trait)}%
          </div>
        </div>
      </Col>
    ));
  } else {
    traitSquare = (
      <Col xs={6} md={4} lg={3}>
        <div className='trait--container d-flex flex-column justify-content-center'>
          <div className='trait--type'>{changeCamelToString(category)}</div>
          <div className='trait--details'>{changeCamelToString(trait)}</div>
          <div className='trait--stats'>
            All: {calculateAllPercentage(category, trait)}%
          </div>
          <div className='trait--stats'>
            {collectionName}: {calculateCollectionPercentage(category, trait)}%
          </div>
        </div>
      </Col>
    );
  }

  return traitSquare;
}
