import React, { createContext, useState } from 'react';
import { switchHelper } from '../util/facetedsearchfunctions';

export const FilterSearchContext = createContext();

const FilterSearchContextProvider = (props) => {
  const initialState = {
    collectionName: [],
    eyes: [],
    xEyes: [],
    hairStyle: [],
    body: [],
    power: [],
    personality: [],
    heart: [],
    companion: [],
    blood: [],
    sign: [],
    soul: [],
    customTraits: [],
  };

  const [selected, setSelected] = useState(initialState);

  const handleClick = (type, category) => {
    let newList;
    if (selected[category].includes(type)) {
      newList = selected[category].filter((inList) => inList !== type);
      switchHelper(newList, category, selected, setSelected);
    } else {
      let newList = [...selected[category], type];

      switchHelper(newList, category, selected, setSelected);
    }
  };

  return (
    <FilterSearchContext.Provider
      value={{ selected, handleClick, setSelected, initialState }}
    >
      {props.children}
    </FilterSearchContext.Provider>
  );
};

export default FilterSearchContextProvider;
