import React, { useContext, useEffect, useState, useRef } from 'react';
import { gql, useQuery } from '@apollo/client';

import { FilterSearchContext } from '../context/FilterSearch';
import { SEARCH_POSTS_QUERY } from '../util/graphql';

import FilterModal from '../components/Modal';
import BrowseCard from '../components/BrowseCard';
import FilterBar from '../components/FilterBar';
import SelectedTraits from '../components/SelectedTraits';
import BrowseCardSkeleton from '../util/BrowseCardSkeleton';

import Button from 'react-bootstrap/Button';
import { Filter } from 'react-bootstrap-icons';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

export default function Browse() {
  const [errors, setErrors] = useState({});
  const { selected, setSelected, initialState } =
    useContext(FilterSearchContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [pageOffset, setPageOffset] = useState(undefined);

  const handleClose = () => setModalOpen(false);
  const handleShow = () => setModalOpen(true);

  const loadMoreButtonRef = useRef(null);

  const clearAll = () => {
    setSelected(initialState);
  };

  let {
    loading,
    data: {
      searchPosts: {
        docs: posts,
        totalDocs,
        totalPages,
        page,
        hasPrevPage,
        hasNextPage,
        prevPage,
        nextPage,
      } = {},
    } = {},
    refetch,
    fetchMore,
  } = useQuery(SEARCH_POSTS_QUERY, {
    onError(err) {
      // setErrors(err.graphQLErrors[0].extensions.exception.errors);
      console.log(err);
    },
    variables: selected,
  });

  useEffect(() => {
    refetch();

    //set page offset to 2, except when someone hits the back button
    setPageOffset(2);
  }, [selected]);

  const getMore = () => {
    let getMoreOffset = Math.floor(posts.length / 36 + 1);
    fetchMore({
      variables: {
        offset: getMoreOffset,
      },
    });
    setPageOffset(getMoreOffset + 1);
    loadMoreButtonRef.current?.blur();
  };

  let numberOfResults = '';

  if (loading) {
    numberOfResults = 'Loading results';
  } else if (totalDocs <= 0) {
    numberOfResults = 'No results';
  } else if (totalDocs > 1) {
    numberOfResults = `${totalDocs} results`;
  } else {
    numberOfResults = '1 result';
  }

  let loadMoreButton;
  let cards = [];

  if (!loading) {
    hasNextPage === true
      ? (loadMoreButton = (
          <Button
            className='browse-loadMore'
            variant='primary'
            ref={loadMoreButtonRef}
            onClick={() => getMore()}
          >
            Load More
          </Button>
        ))
      : (loadMoreButton = (
          <div className='button-text-end'>End of Results</div>
        ));

    cards = posts.map((post) => (
      <Col sm={6} lg={4} xl={3} key={post.id}>
        <BrowseCard post={post} />
      </Col>
    ));
  }
  return (
    <>
      <Container className='currently-searching-container' fluid></Container>
      <Container className='browse--container' fluid>
        <Col>
          <Row className='margin-for-navbar'></Row>
          <Row className='d-flex flex-column'>
            <h2 className='heading'>Browse collection</h2>
            <h5 style={{ marginLeft: '3px' }} className='results-quantity'>
              {numberOfResults}
            </h5>
          </Row>
          <Row className='selected-button-wrapper'>
            <SelectedTraits />
          </Row>
        </Col>

        <Row>
          <Col className='d-flex  justify-content-between search-wrapper-mobile d-lg-none'>
            <div className='clear-all-button align-self-end' onClick={clearAll}>
              Clear all
            </div>
            <Button
              className=''
              onClick={() => setModalOpen(!modalOpen)}
              variant='outline-light'
            >
              <Filter size={30} /> Filter
            </Button>
            <FilterModal
              handleClose={handleClose}
              handleShow={handleShow}
              modalOpen={modalOpen}
            />
          </Col>

          <Col className='search-wrapper d-none d-lg-block' lg={2}>
            <div className='d-flex justify-content-end'>
              <div className='clear-all-button' onClick={clearAll}>
                Clear all
              </div>
            </div>
            <FilterBar />
          </Col>
          <Col lg={10}>
            <div className='card--wrapper'>
              <Row className='card-inner-wrapper'>
                {loading ? (
                  <BrowseCardSkeleton />
                ) : posts.length > 0 ? (
                  <>
                    <div
                      className='d-flex flex-column'
                      style={{ width: '100%' }}
                    >
                      <Row>{cards}</Row>
                      <Row style={{ alignSelf: 'center', maxHeight: '20px' }}>
                        {loadMoreButton}
                      </Row>
                    </div>
                  </>
                ) : (
                  <h3 className='mx-auto'>No results</h3>
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
