export const data = {
  collectionName: [
    'Ghxsts',
    'Demxns',
    'Zxdiacs',
    'Alixns',
    'Sirxns',
    'Immxrtals',
    'Mythxcals',
    'Gxds',
    'Creatxrs',
  ],
};
