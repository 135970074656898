import React, { useContext, useState } from 'react';

import { AuthContext } from '../context/auth';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Discord, Instagram, Twitter } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';

export default function MenuBar() {
  const pathname = window.location.pathname;
  const { user, logout } = useContext(AuthContext);
  const [path, setPath] = useState(
    pathname === '/' ? 'home' : pathname.substr(1)
  );

  const handleItemClick = (e) => {
    setPath(e.target.name);
  };

  return (
    <Navbar className='navbar' fixed='top' expand='lg'>
      <Navbar.Brand href='/' id='logo'>
        <img src={logo} alt='ghxst logo' className='logo' />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      <Navbar.Collapse id='basic-navbar-nav'>
        <Nav className='ml-auto'>
          <Nav.Link
            className='text-center'
            href='https://ghxsts.myshopify.com/'
            target='_blank'
          >
            Merch
          </Nav.Link>
          <Nav.Link
            className='text-center'
            href='https://opensea.io/collection/ghxsts'
            target='_blank'
          >
            Buy
          </Nav.Link>
          <div className='navbar--social--wrapper align-self-center'>
            <Nav.Link
              className='navbar--social--links d-flex align-items-center'
              href='https://twitter.com/Ghxsts'
              target='_blank'
              rel='noreferrer'
            >
              <Twitter />
            </Nav.Link>
            {/* <Nav.Link
              className='navbar--social--links d-flex align-items-center'
              href='https://discord.com/invite/qeDhyhJKkn'
              target='_blank'
              rel='noreferrer'
            >
              <Discord />
            </Nav.Link> */}
            <Nav.Link
              className='navbar--social--links d-flex align-items-center'
              href='https://www.instagram.com/ghxsts_official/'
              target='_blank'
              rel='noreferrer'
            >
              <Instagram />
            </Nav.Link>
          </div>

          {user && (
            <>
              <Link
                className='nav-link navbar--signedin-links text-center'
                to='/new'
              >
                Add Piece
              </Link>

              <Link
                className='nav-link navbar--signedin-links text-center'
                onClick={logout}
              >
                Log Out
              </Link>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
