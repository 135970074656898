import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './stylesheets/App.css';

import MenuBar from './components/Navbar';
import Browse from './pages/browse';
import Login from './pages/login';
import Register from './pages/register';
import Footer from './components/Footer';
import { AuthProvider } from './context/auth';
import FilterSearchContextProvider from './context/FilterSearch';
import { AuthRoute, GuardRoute } from './util/AuthRoute';
import SinglePiece from './pages/singlepiece';
import New from './pages/new';
import Edit from './pages/edit';

function App() {
  return (
    <AuthProvider>
      <FilterSearchContextProvider>
        <Router>
          <MenuBar />
          <Switch>
            <Route exact path='/'>
              <Browse />
            </Route>
            <AuthRoute exact path='/login' component={Login} />
            {/* <AuthRoute exact path='/register' component={Register} /> */}
            <Route exact path='/browse/:slug' component={SinglePiece} />
            <GuardRoute exact path='/edit/:slug' component={Edit} />
            <GuardRoute exact path='/new' component={New} />
          </Switch>
          <Footer />
        </Router>
      </FilterSearchContextProvider>
    </AuthProvider>
  );
}

export default App;
