import React from 'react';

import NoImg from '../images/no-img.png';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';

export default function BrowseCardSkeleton() {
  const content = Array.from({ length: 16 }).map((item, index) => (
    <Col sm={6} lg={4} xl={3} key={index}>
      <Card className='browse--card'>
        <Card.Img variant='top' src={NoImg} />
        <Card.Body className='browse--card--body'>
          <Card.Title className='browse--card--title skeleton-card-title'></Card.Title>
          <Card.Text className='skeleton-card-collectiontext'></Card.Text>
        </Card.Body>
      </Card>
    </Col>
  ));

  return <>{content}</>;
}
