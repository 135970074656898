import React from 'react';
import App from './App';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from 'apollo-link-error';

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) graphQLErrors.map(({ message }) => console.log(message));
});

const httpLink = createHttpLink({
  uri: 'https://ghxsts-server.onrender.com/',
  // uri: 'http://localhost:8080',
});

const authLink = setContext(() => {
  const token = localStorage.getItem('jwtToken');
  return {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          searchPosts: {
            keyArgs: false,
            merge(existing, incoming, args) {
              // console.log('offset ', args.args.offset);

              if (!incoming) return existing;
              const { docs, ...rest } = incoming;
              let result = rest;
              result.args = args.args.filterSearchInput;
              result.docs = docs;
              // console.log(result);
              //first ever render
              if (!existing) {
                // console.log('no existing');
                return result; // existing will be empty the first time

                //after hitting back button
              } else if (
                existing.args.collectionName.length !==
                  args.args.filterSearchInput.collectionName.length ||
                existing.args.eyes.length !==
                  args.args.filterSearchInput.eyes.length ||
                existing.args.xEyes.length !==
                  args.args.filterSearchInput.xEyes.length ||
                existing.args.hairStyle.length !==
                  args.args.filterSearchInput.hairStyle.length ||
                existing.args.body.length !==
                  args.args.filterSearchInput.body.length ||
                existing.args.customTraits.length !==
                  args.args.filterSearchInput.customTraits.length ||
                existing.args.power.length !==
                  args.args.filterSearchInput.power.length ||
                existing.args.personality.length !==
                  args.args.filterSearchInput.personality.length ||
                existing.args.heart.length !==
                  args.args.filterSearchInput.heart.length ||
                existing.args.blood.length !==
                  args.args.filterSearchInput.blood.length ||
                existing.args.companion.length !==
                  args.args.filterSearchInput.companion.length ||
                existing.args.soul.length !==
                  args.args.filterSearchInput.soul.length ||
                existing.args.sign.length !==
                  args.args.filterSearchInput.sign.length
              ) {
                // console.log('search!');
                // console.log(result);
                return result;
              } else if (
                incoming.docs.length > 0 &&
                existing.docs.length > 0 &&
                (incoming.docs[incoming.docs.length - 1].__ref ===
                  existing.docs[existing.docs.length - 1].__ref ||
                  args.args.offset === undefined)
              ) {
                // console.log(
                //   'matching or - have posts and no offset - dont add to cache'
                // );
                // console.log(existing.args.collectionName);
                // console.log(args.args.filterSearchInput.collectionName);
                return existing;
              } else {
                //fetchmore button
                result.docs = [...existing.docs, ...docs];
                // console.log('fetchmore');
                return result;
              }
            },
          },
        },
      },
    },
  }),
});

export default (
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);
