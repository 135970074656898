import React from 'react';

import { Plus, Dash } from 'react-bootstrap-icons';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { Form } from 'react-bootstrap';

export const FilterBarSkeleton = () => {
  return (
    <>
      <Accordion className='filter-accordion' defaultActiveKey='0'>
        <Card className='filter-accordion-card'>
          <Accordion.Toggle
            name='0'
            as={Card.Header}
            eventKey='0'
            className='d-flex justify-content-between'
          >
            <div className='skeleton-filterbar'></div>
          </Accordion.Toggle>
        </Card>
      </Accordion>

      <Accordion name='1'>
        <Card className='filter-accordion-card'>
          <Accordion.Toggle
            name='1'
            as={Card.Header}
            eventKey='1'
            className='d-flex justify-content-between'
          >
            <div className='skeleton-filterbar'></div>
          </Accordion.Toggle>
        </Card>
      </Accordion>
      <Accordion name='2'>
        <Card className='filter-accordion-card'>
          <Accordion.Toggle
            name='2'
            as={Card.Header}
            eventKey='2'
            className='d-flex justify-content-between'
          >
            <div className='skeleton-filterbar'></div>
          </Accordion.Toggle>
        </Card>
      </Accordion>

      <Accordion name='3'>
        <Card className='filter-accordion-card'>
          <Accordion.Toggle
            name='3'
            as={Card.Header}
            eventKey='3'
            className='d-flex justify-content-between'
          >
            <div className='skeleton-filterbar'></div>
          </Accordion.Toggle>
        </Card>
      </Accordion>

      <Accordion name='4'>
        <Card className='filter-accordion-card'>
          <Accordion.Toggle
            name='4'
            as={Card.Header}
            eventKey='4'
            className='d-flex justify-content-between'
          >
            <div className='skeleton-filterbar'></div>
          </Accordion.Toggle>
        </Card>
      </Accordion>
      <Accordion name='5'>
        <Card className='filter-accordion-card'>
          <Accordion.Toggle
            name='5'
            as={Card.Header}
            eventKey='5'
            className='d-flex justify-content-between'
          >
            <div className='skeleton-filterbar'></div>
          </Accordion.Toggle>
        </Card>
      </Accordion>

      <Accordion name='11'>
        <Card className='filter-accordion-card'>
          <Accordion.Toggle
            name='11'
            as={Card.Header}
            eventKey='11'
            className='d-flex justify-content-between'
          >
            <div className='skeleton-filterbar'></div>
          </Accordion.Toggle>
        </Card>
      </Accordion>
      <Accordion name='11'>
        <Card className='filter-accordion-card'>
          <Accordion.Toggle
            name='11'
            as={Card.Header}
            eventKey='11'
            className='d-flex justify-content-between'
          >
            <div className='skeleton-filterbar'></div>
          </Accordion.Toggle>
        </Card>
      </Accordion>
      <Accordion name='11'>
        <Card className='filter-accordion-card'>
          <Accordion.Toggle
            name='11'
            as={Card.Header}
            eventKey='11'
            className='d-flex justify-content-between'
          >
            <div className='skeleton-filterbar'></div>
          </Accordion.Toggle>
        </Card>
      </Accordion>
      <Accordion name='11'>
        <Card className='filter-accordion-card'>
          <Accordion.Toggle
            name='11'
            as={Card.Header}
            eventKey='11'
            className='d-flex justify-content-between'
          >
            <div className='skeleton-filterbar'></div>
          </Accordion.Toggle>
        </Card>
      </Accordion>
      <Accordion name='11'>
        <Card className='filter-accordion-card'>
          <Accordion.Toggle
            name='11'
            as={Card.Header}
            eventKey='11'
            className='d-flex justify-content-between'
          >
            <div className='skeleton-filterbar'></div>
          </Accordion.Toggle>
        </Card>
      </Accordion>
    </>
  );
};
