import React, { useContext, useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import { AuthContext } from '../context/auth';
import { useForm } from '../util/hooks';
import { FETCH_POST_QUERY, EDIT_POST_MUTATION } from '../util/graphql';

import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

export default function New(props) {
  const slug = props.match.params.slug;
  const [errors, setErrors] = useState({});
  const { values, setValues, onChange, onSubmit } = useForm(
    createPostCallback,
    {
      name: '',
      collectionName: '',
      imageUrl: '',
      openseaUrl: '',
      dateReleased: '',
      eyes: '',
      xEyes: '',
      backgroundTexture: '',
      hairStyle: '',
      lips: '',
      body: '',
      combinations: '',
      piecesUsed: '',
      power: '',
      personality: '',
      heart: '',
      blood: '',
      companion: '',
      sign: '',
      soul: '',
    }
  );

  const { data: { getPost } = {} } = useQuery(FETCH_POST_QUERY, {
    variables: {
      slug,
    },
  });
  const insertAt = (str, sub, pos) =>
    `${str.slice(0, pos)}${sub}${str.slice(pos)}`;
  useEffect(() => {
    if (getPost) {
      let combinationsList = [];
      getPost.combinations.forEach((combination) => {
        combinationsList.push(combination.name);
      });
      let stringifiedCombinationsList = combinationsList.join();

      let piecesUsedList = [];
      getPost.piecesUsed.forEach((pieceUsed) => {
        piecesUsedList.push(pieceUsed.name);
      });
      let stringifiedPiecesUsedList = piecesUsedList.join();

      //setCustomTraits, but need to remove __typename
      let customTraitsMinusTypename = [];

      getPost.customTraits.forEach((customTrait) => {
        let newCustomTraitMinusTypename = {};
        newCustomTraitMinusTypename.trait = customTrait.trait;
        newCustomTraitMinusTypename.description = customTrait.description;
        newCustomTraitMinusTypename.allPercentage = customTrait.allPercentage;
        newCustomTraitMinusTypename.collectionPercentage =
          customTrait.collectionPercentage;
        customTraitsMinusTypename.push(newCustomTraitMinusTypename);
      });

      setCustomTraits(customTraitsMinusTypename);
      let formattedDate;
      if (getPost.dateReleased !== null) {
        formattedDate = insertAt(
          insertAt(getPost.dateReleased, '/', 2),
          '/',
          5
        );
      } else {
        formattedDate = '';
      }
      setValues({
        ...getPost,
        dateReleased: formattedDate,
        combinations: stringifiedCombinationsList,
        piecesUsed: stringifiedPiecesUsedList,
      });
    }
  }, [getPost]);

  const [customTraits, setCustomTraits] = useState([]);

  const [customTraitData, setCustomTraitData] = useState({
    trait: '',
    description: '',
    allPercentage: '',
    collectionPercentage: '',
  });

  const handleCustomTraitChange = (event) => {
    setCustomTraitData({
      ...customTraitData,
      [event.target.name]: event.target.value,
    });
  };

  const addTrait = () => {
    {
      setCustomTraits([...customTraits, customTraitData]);
      setErrors({ ...errors, collectionPercentage: '', allPercentage: '' });
      setCustomTraitData({
        trait: '',
        description: '',
        allPercentage: '',
        collectionPercentage: '',
      });
    }
  };

  const removeCustomTrait = (customTraitTrait) => {
    setCustomTraits(
      customTraits.filter((trait) => trait.trait !== customTraitTrait)
    );
  };

  const [editPost, { error, loading }] = useMutation(EDIT_POST_MUTATION, {
    variables: { ...values, customTraits, slug },
    update(_, data) {
      console.log(data);
      props.history.push(`/browse/${data.data.editPost.slug}`);
    },
    onError(err) {
      console.log(err);
      setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
  });

  function createPostCallback() {
    editPost();
  }

  return (
    <Container className='new--container'>
      <h2 className='text-center mb-5'>Edit Piece</h2>
      <Form>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label className={errors.name && 'text-danger'}>
              {errors.name ?? 'Name (ex: Ghxst 001):'}
            </Form.Label>
            <Form.Control
              name='name'
              type='text'
              className={errors.name && 'is-invalid'}
              value={values.name}
              onChange={onChange}
              autoComplete='off'
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label className={errors.collectionName && 'text-danger'}>
              {errors.collectionName ?? 'Collection Name (Choose one):'}
            </Form.Label>
            <Form.Control
              as='select'
              name='collectionName'
              type='text'
              className={errors.collectionName && 'is-invalid'}
              value={values.collectionName}
              onChange={onChange}
            >
              <option>Choose...</option>
              <option>Ghxsts</option>
              <option>Demxns</option>
              <option>Zxdiacs</option>
              <option>Alixns</option>
              <option>Sirxns</option>
              <option>Immxrtals</option>
              <option>Mythxcals</option>
              <option>Gxds</option>
              <option>Creatxrs</option>
            </Form.Control>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label className={errors.collectionName && 'text-danger'}>
              {errors.imageUrl ?? 'Image Url:'}
            </Form.Label>

            <Form.Control
              className={errors.imageUrl && 'is-invalid'}
              name='imageUrl'
              type='text'
              value={values.imageUrl}
              onChange={onChange}
              autoComplete='off'
            ></Form.Control>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label className={errors.openseaUrl && 'text-danger'}>
              {errors.openseaUrl ?? 'Opensea Url:'}
            </Form.Label>

            <Form.Control
              className={errors.openseaUrl && 'is-invalid'}
              name='openseaUrl'
              type='text'
              value={values.openseaUrl}
              onChange={onChange}
              autoComplete='off'
            ></Form.Control>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label className={errors.dateReleased && 'text-danger'}>
              {errors.dateReleased ?? 'Date Released (MM/DD/YYYY Format):'}
            </Form.Label>

            <Form.Control
              className={errors.dateReleased && 'is-invalid'}
              name='dateReleased'
              type='text'
              value={values.dateReleased}
              onChange={onChange}
            ></Form.Control>
          </Form.Group>
          <Form.Group as={Col}></Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label className={errors.eyes && 'text-danger'}>
              {errors.eyes ?? 'Eyes:'}
            </Form.Label>

            <Form.Control
              className={errors.eyes && 'is-invalid'}
              name='eyes'
              type='text'
              value={values.eyes}
              onChange={onChange}
            ></Form.Control>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label className={errors.xEyes && 'text-danger'}>
              {errors.xEyes ?? 'X Eyes:'}
            </Form.Label>
            <Form.Control
              className={errors.xEyes && 'is-invalid'}
              name='xEyes'
              type='text'
              value={values.xEyes}
              onChange={onChange}
            ></Form.Control>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label className={errors.body && 'text-danger'}>
              {errors.body ?? 'Body:'}
            </Form.Label>

            <Form.Control
              className={errors.body && 'is-invalid'}
              name='body'
              type='text'
              value={values.body}
              onChange={onChange}
            ></Form.Control>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label className={errors.hairStyle && 'text-danger'}>
              {errors.hairStyle ?? 'Hair:'}
            </Form.Label>

            <Form.Control
              className={errors.hairStyle && 'is-invalid'}
              name='hairStyle'
              type='text'
              value={values.hairStyle}
              onChange={onChange}
            ></Form.Control>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label className={errors.power && 'text-danger'}>
              {errors.power ?? 'Power:'}
            </Form.Label>

            <Form.Control
              className={errors.power && 'is-invalid'}
              name='power'
              type='text'
              value={values.power}
              onChange={onChange}
            ></Form.Control>
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label className={errors.personality && 'text-danger'}>
              {errors.personality ?? 'Personality:'}
            </Form.Label>

            <Form.Control
              className={errors.personality && 'is-invalid'}
              name='personality'
              type='text'
              value={values.personality}
              onChange={onChange}
            ></Form.Control>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label className={errors.heart && 'text-danger'}>
              {errors.heart ?? 'Heart:'}
            </Form.Label>

            <Form.Control
              className={errors.heart && 'is-invalid'}
              name='heart'
              type='text'
              value={values.heart}
              onChange={onChange}
            ></Form.Control>
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label className={errors.blood && 'text-danger'}>
              {errors.blood ?? 'Blood:'}
            </Form.Label>

            <Form.Control
              className={errors.blood && 'is-invalid'}
              name='blood'
              type='text'
              value={values.blood}
              onChange={onChange}
            ></Form.Control>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label className={errors.companion && 'text-danger'}>
              {errors.companion ?? 'Companion:'}
            </Form.Label>

            <Form.Control
              className={errors.companion && 'is-invalid'}
              name='companion'
              type='text'
              value={values.companion}
              onChange={onChange}
            ></Form.Control>
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label className={errors.sign && 'text-danger'}>
              {errors.sign ?? 'Sign:'}
            </Form.Label>

            <Form.Control
              className={errors.sign && 'is-invalid'}
              name='sign'
              type='text'
              value={values.sign}
              onChange={onChange}
            ></Form.Control>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label className={errors.soul && 'text-danger'}>
              {errors.soul ?? 'Soul:'}
            </Form.Label>

            <Form.Control
              className={errors.soul && 'is-invalid'}
              name='soul'
              type='text'
              value={values.soul}
              onChange={onChange}
            ></Form.Control>
          </Form.Group>

          <Form.Group as={Col}></Form.Group>
        </Form.Row>
        <Form.Row>
          <h6>Custom Traits (Optional):</h6>
        </Form.Row>
        <Row>
          {customTraits.length > 0 &&
            customTraits.map((trait) => (
              <Card
                className='d-flex'
                style={{
                  padding: '.5rem',
                  margin: '1rem',
                  backgroundColor: 'black',
                  opacity: '.9',
                }}
                key={trait.trait}
              >
                <div>
                  <span className='customTrait--heading'>Trait: </span>
                  {trait.trait}
                </div>
                <Button
                  className='button--removeCustomTrait'
                  onClick={() => removeCustomTrait(trait.trait)}
                  variant='danger'
                >
                  Remove
                </Button>
              </Card>
            ))}
        </Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Trait (ex: Animated Background):</Form.Label>
            <Form.Control
              name='trait'
              type='text'
              value={customTraitData.trait}
              onChange={handleCustomTraitChange}
            ></Form.Control>
          </Form.Group>

          <Button variant='warning button--addTraitButton' onClick={addTrait}>
            Add Trait
          </Button>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label className={errors.combinations && 'text-danger'}>
              {errors.combinations ?? 'Combinations:'}
            </Form.Label>

            <Form.Control
              className={errors.combinations && 'is-invalid'}
              name='combinations'
              type='text'
              value={values.combinations}
              onChange={onChange}
            ></Form.Control>
            <Form.Text className='text-muted'>
              Enter name(s) separated by a comma
            </Form.Text>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label className={errors.piecesUsed && 'text-danger'}>
              {errors.piecesUsed ?? 'Pieces Used:'}
            </Form.Label>

            <Form.Control
              className={errors.piecesUsed && 'is-invalid'}
              name='piecesUsed'
              type='text'
              value={values.piecesUsed}
              onChange={onChange}
            ></Form.Control>
            <Form.Text className='text-muted'>
              Enter name(s) separated by a comma
            </Form.Text>
          </Form.Group>
        </Form.Row>
        <div className='text-center'>
          {Object.keys(errors).length > 0 ? (
            <Button onClick={onSubmit} variant='danger' disabled={loading}>
              Please fix errors and resubmit
            </Button>
          ) : (
            <Button onClick={onSubmit} variant='success' disabled={loading}>
              {loading ? 'loading...' : 'Submit'}
            </Button>
          )}
        </div>
      </Form>
    </Container>
  );
}
