export const switchHelper = (newList, category, selected, setSelected) => {
  switch (category) {
    case 'collectionName':
      setSelected({ ...selected, collectionName: newList });
      break;
    case 'eyes':
      setSelected({ ...selected, eyes: newList });
      break;
    case 'xEyes':
      setSelected({ ...selected, xEyes: newList });
      break;
    case 'customTraits':
      setSelected({ ...selected, customTraits: newList });
      break;
    case 'hairStyle':
      setSelected({ ...selected, hairStyle: newList });
      break;
    case 'body':
      setSelected({ ...selected, body: newList });
      break;
    case 'power':
      setSelected({ ...selected, power: newList });
      break;
    case 'personality':
      setSelected({ ...selected, personality: newList });
      break;
    case 'heart':
      setSelected({ ...selected, heart: newList });
      break;
    case 'blood':
      setSelected({ ...selected, blood: newList });
      break;
    case 'companion':
      setSelected({ ...selected, companion: newList });
      break;
    case 'sign':
      setSelected({ ...selected, sign: newList });
      break;
    case 'soul':
      setSelected({ ...selected, soul: newList });
      break;

    default:
      break;
  }
};
