import React from 'react';
import { gql, useQuery } from '@apollo/client';

import Col from 'react-bootstrap/Col';

import SinglePieceTraitSquare from '../components/SinglePieceTraitSquare';

//function for changing camel case to regular string
const changeCamelToString = (camelCasedWord) => {
  return (
    camelCasedWord
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
};

export default function SinglePieceTraitCalculations({ getPost }) {
  let {
    collectionName,
    eyes,
    xEyes,
    hairStyle,
    body,
    power,
    personality,
    blood,
    heart,
    companion,
    customTraits,
    sign,
    soul,
  } = getPost;

  //   fetch trait data and all trait data for calculations

  const { loading: loadingR, data: allData } = useQuery(FETCH_TRAITDATA_QUERY, {
    variables: { collectionName: 'All' },
  });

  const { loading, data: collectionData } = useQuery(FETCH_TRAITDATA_QUERY, {
    variables: { collectionName },
  });

  let content;
  if (loadingR || loading) {
    content = <h1>loading...</h1>;
  } else {
    const allDataParsed = JSON.parse(allData.getTraitData.stringifiedData);

    const collectionDataParsed = JSON.parse(
      collectionData.getTraitData.stringifiedData
    );

    const calculateCustomTraitsCollectionPercentage = (_traitOfPiece) => {
      let percentage = (
        (collectionDataParsed['customTraits'][_traitOfPiece] /
          collectionData.getTraitData.totalCount) *
        100
      ).toFixed(2);
      return percentage;
    };

    const calculateCustomTraitsAllPercentage = (_traitOfPiece) => {
      let percentage = (
        (allDataParsed['customTraits'][_traitOfPiece] /
          allData.getTraitData.totalCount) *
        100
      ).toFixed(2);
      return percentage;
    };

    content = (
      <>
        {eyes && eyes !== 'None' && eyes !== 'Null' && eyes !== 'Zxdiacs' && (
          <SinglePieceTraitSquare
            category={'eyes'}
            trait={eyes}
            collectionData={collectionDataParsed}
            allData={allDataParsed}
            collectionCount={collectionData.getTraitData.totalCount}
            totalCount={allData.getTraitData.totalCount}
            collectionName={collectionName}
          />
        )}
        {xEyes &&
          xEyes !== 'None' &&
          xEyes !== 'Null' &&
          xEyes !== 'Zxdiacs' && (
            <SinglePieceTraitSquare
              category={'xEyes'}
              trait={xEyes}
              collectionData={collectionDataParsed}
              allData={allDataParsed}
              collectionCount={collectionData.getTraitData.totalCount}
              totalCount={allData.getTraitData.totalCount}
              collectionName={collectionName}
            />
          )}
        {hairStyle &&
          hairStyle !== 'None' &&
          hairStyle !== 'Null' &&
          hairStyle !== 'Zxdiacs' && (
            <SinglePieceTraitSquare
              category={'hairStyle'}
              trait={hairStyle}
              collectionData={collectionDataParsed}
              allData={allDataParsed}
              collectionCount={collectionData.getTraitData.totalCount}
              totalCount={allData.getTraitData.totalCount}
              collectionName={collectionName}
            />
          )}
        {body && body !== 'None' && body !== 'Null' && body !== 'Zxdiacs' && (
          <SinglePieceTraitSquare
            category={'body'}
            trait={body}
            collectionData={collectionDataParsed}
            allData={allDataParsed}
            collectionCount={collectionData.getTraitData.totalCount}
            totalCount={allData.getTraitData.totalCount}
            collectionName={collectionName}
          />
        )}
        {power !== 'None' && power !== 'Null' && power !== 'Zxdiacs' && (
          <SinglePieceTraitSquare
            category={'power'}
            trait={power}
            collectionData={collectionDataParsed}
            allData={allDataParsed}
            collectionCount={collectionData.getTraitData.totalCount}
            totalCount={allData.getTraitData.totalCount}
            collectionName={collectionName}
          />
        )}

        {personality !== 'None' &&
          personality !== 'Null' &&
          personality !== 'Zxdiacs' && (
            <SinglePieceTraitSquare
              category={'personality'}
              trait={personality}
              collectionData={collectionDataParsed}
              allData={allDataParsed}
              collectionCount={collectionData.getTraitData.totalCount}
              totalCount={allData.getTraitData.totalCount}
              collectionName={collectionName}
            />
          )}
        {heart !== 'None' && heart !== 'Null' && heart !== 'Zxdiacs' && (
          <SinglePieceTraitSquare
            category={'heart'}
            trait={heart}
            collectionData={collectionDataParsed}
            allData={allDataParsed}
            collectionCount={collectionData.getTraitData.totalCount}
            totalCount={allData.getTraitData.totalCount}
            collectionName={collectionName}
          />
        )}
        {blood !== 'None' && blood !== 'Null' && blood !== 'Zxdiacs' && (
          <SinglePieceTraitSquare
            category={'blood'}
            trait={blood}
            collectionData={collectionDataParsed}
            allData={allDataParsed}
            collectionCount={collectionData.getTraitData.totalCount}
            totalCount={allData.getTraitData.totalCount}
            collectionName={collectionName}
          />
        )}
        {companion !== 'None' &&
          companion !== 'Null' &&
          companion !== 'Zxdiacs' && (
            <SinglePieceTraitSquare
              category={'companion'}
              trait={companion}
              collectionData={collectionDataParsed}
              allData={allDataParsed}
              collectionCount={collectionData.getTraitData.totalCount}
              totalCount={allData.getTraitData.totalCount}
              collectionName={collectionName}
            />
          )}
        {soul && soul !== 'None' && soul !== 'Null' && soul !== 'Zxdiacs' && (
          <SinglePieceTraitSquare
            category={'soul'}
            trait={soul}
            collectionData={collectionDataParsed}
            allData={allDataParsed}
            collectionCount={collectionData.getTraitData.totalCount}
            totalCount={allData.getTraitData.totalCount}
            collectionName={collectionName}
          />
        )}
        {sign && sign !== 'None' && sign !== 'Null' && sign !== 'Zxdiacs' && (
          <SinglePieceTraitSquare
            category={'sign'}
            trait={sign}
            collectionData={collectionDataParsed}
            allData={allDataParsed}
            collectionCount={collectionData.getTraitData.totalCount}
            totalCount={allData.getTraitData.totalCount}
            collectionName={collectionName}
          />
        )}
        {customTraits &&
          customTraits.map((customTrait) => (
            <Col
              key={customTrait.trait ? customTrait.trait : ''}
              xs={6}
              md={4}
              lg={3}
            >
              <div className='trait--container d-flex flex-column justify-content-center'>
                <div className='trait--type'>Rarity</div>
                <div className='trait--details'>{customTrait.trait}</div>
                <div className='trait--stats'>
                  All: {calculateCustomTraitsAllPercentage(customTrait.trait)}%
                </div>
                <div className='trait--stats'>
                  {collectionName}:{' '}
                  {calculateCustomTraitsCollectionPercentage(customTrait.trait)}
                  %
                </div>
              </div>
            </Col>
          ))}
      </>
    );
  }

  return <>{content} </>;
}

const FETCH_TRAITDATA_QUERY = gql`
  query ($collectionName: String!) {
    getTraitData(collectionName: $collectionName) {
      totalCount
      stringifiedData
    }
  }
`;
